<template>
  <v-card elevation="0" class="w-full">
    <v-card-title>Plantilla Avance Montos Solicitados.</v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row>
        <v-col cols="5">
          <AmountDownloadExcel/>
        </v-col>
          <v-divider class="mx-4" inset vertical></v-divider>
        <v-col cols="5">
          <AmountUploadExcel/>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-12">
          <AmountTableComponent
          :rows = this.projects
          :loading = this.loading
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import AmountDownloadExcel from "./AmountDownloadExcel";
import AmountUploadExcel from "./AmountUploadExcel";
import AmountTable from "@/components/Amount/AmountTable";
import {mapActions} from "vuex";
export default {
  name: "AmountsExcel",
  components:{
    'AmountDownloadExcel': AmountDownloadExcel,
    'AmountUploadExcel': AmountUploadExcel,
    'AmountTableComponent': AmountTable
  },
  data(){
    return {
      loading: false,
      projects: []
    }
  },
  methods: {
    ...mapActions('main', [
      'getAllProjectAmountRequest',
    ]),
    getAllAmountRequest(){
      this.loading = true;
      this.getAllProjectAmountRequest().then(response => {
        this.projects = response.data.response;
        this.loading = false;
      }).catch(error => {
        this.loading = false;
        console.log(error.message);
      })
    },
    getDateFormatter(timestamp){
      let date = new Date(timestamp);
      var t = date.toISOString().split(/[- : T ]/);
      return `${t[2]}-${t[1]}-${t[0]} ${t[3]}:${t[4]}`;
    }
  },
  created() {
    this.getAllAmountRequest();
  }
}
</script>

<style scoped>

</style>