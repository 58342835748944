<template>
  <v-row>
    <v-col class="text-left">Descarga plantilla en excel de los montos solicitados por proyectos clasificado por periodo.</v-col>
    <v-col class="col-12">
      <v-select
          placeholder="Seleccione periodo para generar informe"
          :items="periodos"
          :item-text="'name'"
          :item-value="'id'"
          icon="mdi-arrow-bottom-right-thin-circle-outline"
          label="Periodo del informe"
          v-model="periodoSeleccionado"
      />
      <v-btn class="primary" @click.prevent="downloadExcel()" :loading="loading">Descargar Plantilla Excel</v-btn>
    </v-col>
  </v-row>
</template>

<script>
import {mapActions} from "vuex";
import Swal from "sweetalert2";
export default {
  name: "DownloadExcelProjectAmount",
  data(){
    return {
      'loading':false,
      periodos: [],
      periodoSeleccionado: new Date().getFullYear()
    }
  },
  methods: {
    ...mapActions('main', [
      'getExcelAdvanceProjectAmount',
    ]),
    setPeriod(){
      this.periodos.push({id: 0, name: "Seleccione"})
      for (let i = 2021; i <= new Date().getFullYear(); i++){
        this.periodos.push({id: i, name: i})
      }
    },
    downloadExcel(){
      this.loading = true;
      this.getExcelAdvanceProjectAmount(this.periodoSeleccionado).then(response => {
        this.download(response);
        this.loading = false;
      }).catch(async err => {
          let error = await err.response.data.message;
          console.log(error);
          this.messageFailRequest(error);
          this.loading = false;
      });
    },
    messageFailRequest(error){
      Swal.fire({
        title: 'Descargar Plantilla Excel',
        text: error,
        confirmButtonColor: '#EA7600',
        confirmButtonText: 'Aceptar',
        icon: 'warning',
      });
    },
    download(response){
      const {content, filename, mime} = response.data.response;
      const linkSource = `data:${mime};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    }
  },
  created() {
    this.setPeriod();
  }
}
</script>

<style scoped>

</style>