<template>
  <div class="pt-15 my-10 main-component-content">
    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-col class="mb-8 mt-0 mx-0 pa-0" align-self="start">
          <h1 class="text-left w-full mb-2"></h1>
          <hr>
          <AmountDownloadUpload/>
          <hr>
        </v-col>
        <div class="welcome-link-group mb-8">
          <v-btn style="background-color: #EA7730; color: white"
                 dark class="mr-md-1" @click="$router.push('/')">Volver</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AmountDownloadUpload from "@/components/Amount/AmountDownloadUpload.vue";

export default {
  name: "UploadProjectAmount",
  components:{
    'AmountDownloadUpload': AmountDownloadUpload,
  }
}
</script>

<style scoped>

</style>