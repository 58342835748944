<template>
  <v-row>
    <v-col class="text-left">Subir plantilla en excel de los montos solicitados por proyectos.</v-col>
    <v-col cols="12">
      <v-file-input
          v-model="file"
          label="Seleccione el archivo excel para subir"
          accept="
          application/vnd.ms-excel,
          application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
          application/vnd.openxmlformats-officedocument.spreadsheetml.template"
          show-size
          counter
          chips
          truncate-length="50"
          :clearable="false"
          :loading="loading">
      </v-file-input>
    <v-btn class="primary"
           :disabled="file===null"
           :loading="loading"
           @click="uploadExcelFile"
    >Subir Plantilla Excel</v-btn>
    </v-col>
<!--    <v-col cols="2">-->
<!--      <v-btn class="primary"-->
<!--             :disabled="file===null"-->
<!--             :loading="loading"-->
<!--             @click="uploadExcelFile"-->
<!--      >Subir Plantilla Excel</v-btn>-->
<!--    </v-col>-->
  </v-row>
</template>
<script>
import {mapActions} from "vuex";
import Swal from "sweetalert2";
export default {
  name: "UploadExcelProjectAmount",
  data() {
    return {
      'file': null,
      'loading': false
    }
  },
  methods: {
    ...mapActions('main', [
      'uploadAmountExcelFile',
    ]),
    uploadExcelFile() {
      this.loading=true;
      this.uploadAmountExcelFile(
          {
            'page': 0,
            'cols': 7,
            'file': this.file
          }
      ).then(
          // eslint-disable-next-line no-unused-vars
        response => {
          this.download(response);
          Swal.fire({
            title: 'Subir Plantilla Excel',
            text: "Información cargada exitosamente",
            confirmButtonColor: '#EA7600',
            confirmButtonText: 'Aceptar',
            icon: 'success',
          })
          this.loading = false;
      }).catch(async err=>{
        console.log(err.response);
        Swal.fire({
          title: 'Subir Plantilla Excel',
          text: "Se ha producido un error en el proceso de carga del archivo excel",
          confirmButtonColor: '#EA7600',
          confirmButtonText: 'Aceptar',
          icon: 'warning',
        })
        this.loading = false;
      });
    },
    download(response){
      const {content, filename, mime} = response.data.response;
      const linkSource = `data:${mime};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = filename;
      downloadLink.click();
    }
  }
}
</script>
<style scoped>
</style>