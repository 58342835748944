<template>
  <v-card>
    <v-card-title>Tabla con listado de proyectos y avance montos solicitados. </v-card-title>
    <v-card-text>
      <v-data-table
          :loading="this.loading"
          :headers="this.headers"
          :items="this.rows"
          item-key="id"
          class="elevation-1">
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "AmountTable",
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    loading:{
      type: Boolean,
      default: () => false
    }
  },
  data(){
    return{
      headers: [
        { text: 'Periodo', value: 'createdAt'},
        { text: 'Codigo Proyecto', value: 'id', align: 'start', sortable: true },
        { text: 'CCValor', value: 'centroCosto.ccValor' },
        { text: 'CCNombre', value: 'centroCosto.ccNombre' },
        { text: 'Nombre Proyecto', value: 'nombre', width: "30%"},
        { text: 'Monto Solicitado', value: 'amountRequested' },
        { text: '% de lo solicitado por proyecto', value: 'percentageAmountRequestedProject' },
        { text: '% de lo solicitado por unidad', value: 'percentageAmountRequestedCostCenter' },
        { text: 'Fecha avance solicitud', value: 'amountRequestedDate' },
      ],
    }
  }
}
</script>

<style scoped>

</style>